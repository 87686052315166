import {
  secureGet, securePost, securePut, secureDelete,
} from 'config/apiConfig';

export const defaultMessage = 'Erreur système';

export const getAnnouncements = async ({ page = 0, size = 20, sort = '' }, search) => {
  const { data } = await secureGet(`/announcements?page=${page}&size=${size}&sort=${sort}&title=${search || ''}`);
  return data;
};

export const addAttachement = async (file) => {
  const bodyFormData = new FormData();
  bodyFormData.set('file', file);

  const { data } = await securePost('/attachments/announcement/add', bodyFormData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }, false);
  return data;
};

export const addAnnouncements = async (announce) => {
  const body = {
    ...announce,
    fileEvent: announce.fileEvent?.length === 0 ? null : announce.fileEvent,
    imageEvent: announce.imageEvent?.length === 0 ? null : announce.imageEvent,
  };
  const { data } = await securePut('/announcements/add', body);
  return data;
};

export const deleteAnnouncements = async (id) => {
  await secureDelete(`/announcements/delete/${id}`);
  return true;
};
